require('dotenv').config();

import React from 'react';
import ToasterComponent from './Components/UI/Toaster';
import Routes from './Routes';
import './Assets/css/bootstrap.min.css';
import './Assets/css/icons.css';
import './Assets/css/common.css';
import './Assets/css/dashboard.css';
import './Assets/css/login.css';
import './Assets/css/registration_page.css';
import './Assets/css/forgot_password.css';
import './Assets/css/user_management.css';
import './Assets/css/sidepanel.css';

function App() {
  return (
    <div>
      <Routes />
      <ToasterComponent />
    </div>
  );
}

export default App;
